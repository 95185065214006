import config from '@config';
import xObject from '../common/xObject';
import { uniqueId } from '../common/Functions';

declare let window: any;

export enum TrackerEcommerceEventType
{
    ViewProducts,
    AddToWishList,
    AddToCart,
    RemoveFromCart,
    ViewCart,
    InitiateCheckout,
    AddPaymentInfo,
    Purchase,
    PaymentFailed,
    ChangePaymentMethod,
    Refund,
    ReserveProduct
}

/** Tracking info for a product */
export interface TrackerProduct
{
    id: number | string;
    name: string;
    price?: number;
    quantity?: number;
    category?: string;
    brand?: string;
    variant?: string,
    discount?: number;
    coupon?: number;
    url?: string;
    imageUrl?: string;
}

/** Tracking data for an eCommerce event. */
export interface TrackerEcommerceData
{
    type: TrackerEcommerceEventType,
    value: number;
    currency: string;
    products: TrackerProduct[];
    category?: string;
    couponCode?: string;
    transactionId?: number | string;
    shippingCost?: number;
    tax?: number;
    paymentType?: string;
}

/** Extra user info for  enhanced Google Ads and/or Facebook Conversions API. */
export interface TrackerExtraUserData 
{
    emails?: string[],
    phoneNumbers?: string[],
    firstName?: string,
    lastName?: string,
    postalCode?: string,
    streetAddress?: string,
    countryCode?: string,
    /** Google Analytics client ID (from the _ga cookie). */
    gaClientId?: string,
    /** Facebook pixel fbp cookie. */
    fbp?: string,
    /** Facebook pixel fbc cookie. */
    fbc?: string,
    /** External IDs for user identification */
    externalIds?: string[],
}

/**
 * Track user interactions with the app.
 * You should always inherit from this class and override the createInstance() and init() methods.
 */
export class Tracker extends xObject
{
    /** The singleton instance. */
    protected static _instance?: Tracker;
    /** Does the Tracker load the tracking scripts or are they already loaded with the classic <script> tags? */
    handleScriptLoading: boolean = true;
    /** Have the scripts started loading? */
    loadingScriptsStarted = false;
    /** Are the scripts loaded? */
    scriptsAreLoaded = false;
    /** Is tracking enabled? */
    enabled = true;
    /** Track with Google Analytics/ Google Ads? */
    trackWithGoogle = false;
    /** Track with Facebook? */
    trackWithFacebook = false;
    /** Track with the Google Analytics Measurement Protocol? */
    trackWithGoogleMeasurementProtocol = false;
    /** Track with the Facebook Conversions API? */
    trackWithFacebookConversionsApi = false;
    /** Track with X (Twitter)? */
    trackWithTwitter = false;
    /** Track with Tik Tok? */
    trackWithTikTok = false;
    /** Track with Tik Tok Events API? */
    trackWithTikTokEventApi = false;
    /** Track with Reddit? */
    trackWithReddit: boolean = false;
    /** Track with the Reddit Conversions API? */
    trackWithRedditConversionsApi: boolean = false;
    /** Track the page view when the scripts load? */
    trackPageViewAtScriptLoad = true;
    /** Lazy load the tracking scripts? Eg: on use interaction or after a certain time passes after the page load. */
    lazyLoadScripts = false;
    /** The Google gtag object. */
    gtag?: Gtag.Gtag;
    /** The Facebook fbq object. */
    fbq?: facebook.Pixel.Event;
    /** The Twitter twq object. */
    twq?: any;
    /** The TikTok ttq object. */
    ttq?: any;
    /** The Reddit rdt object. */
    rdt?: any;

    //#region Singleton
    protected constructor()
    {
        super();

        this.init();
    }

    static get instance(): Tracker
    {
        if (this._instance === undefined) this._instance = this.createInstance();

        return this._instance;
    }

    /**
     * Create the singleton instance. Override this method to create a custom instance.
     */
    protected static createInstance(): Tracker
    {
        return new Tracker();
    }
    //#endregion

    /**
     * Load the tracking scripts.
     * Override this method to initialize the child class.
     */
    protected async init()
    {
        if (this.handleScriptLoading)
        {// scripts are loaded by the Tracker
            if (this.lazyLoadScripts)
            {// Load the tracking scripts when a user interacts with the page or a certain amount of time expires after the page load
                if (typeof window !== 'undefined')
                {
                    addEventListener('scroll', () => this.loadScripts());
                    addEventListener('mousemove', () => this.loadScripts());
                    addEventListener('touchstart', () => this.loadScripts());
                    addEventListener('touchmove', () => this.loadScripts());
                    addEventListener('click', () => this.loadScripts());
                }

                let timeout = 5000;
                setTimeout(() => this.loadScripts(), timeout);
            }
            else
            {// Load the tracking scripts immediately
                this.loadScripts();
            }
        }
        else
        {// scripts are already loaded with the classic <script> tags: set the tag objects and fire the 'loaded' event
            addEventListener('load', () =>
            {
                this.gtag = window.gtag;
                this.forceGoogleConsent();
                this.fbq = window.fbq;
                this.twq = window.twq;
                this.ttq = window.ttq;
                this.rdt = window.rdt;
                this.fire('loaded');
                this.scriptsAreLoaded = true;
            });
        }
    }

    /**
     * Load the tracking scripts. Fire the 'loaded' event when done.
     * 
     * @param extraLoader An extra loader function to load additional tracking scripts.
     */
    protected async loadScripts(extraLoader?: () => Promise<void>)
    {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;

        if (this.loadingScriptsStarted) return;
        this.loadingScriptsStarted = true;

        console.log('Tracker: loading tracking scripts...');

        await Promise.all([
            this.loadFacebookScript(),
            this.loadGoogleScript(),
            this.loadTwitterScript(),
            this.loadTikTokScript(),
            this.loadRedditScript(),
            extraLoader?.()
        ]);
        this.fire('loaded');
        this.scriptsAreLoaded = true;

        console.log('Tracker: tracking scripts loaded.');
    }

    /**
    * Load the Google Analytics tracking code.
    * https://developers.google.com/analytics/devguides/collection/gtagjs
    * https://developers.google.com/tag-platform/tag-manager/web/datalayer
    * https://support.google.com/google-ads/answer/7548399
    * https://support.google.com/google-ads/answer/12785474?visit_id=638155969923163305-1440919079&rd=1#
    */
    private loadGoogleScript()
    {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;

        return new Promise<void>((resolve) =>
        {
            console.log('Tracker: loading Google Analytics...');

            if (!this.trackWithGoogle)
            {
                console.log('Tracker: Google Analytics disabled.');
                resolve();
                return;
            }

            // Load the tracking script
            let script = document.createElement('script');
            script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalyticsId;
            script.async = true;
            script.onload = () =>
            {
                // Initialize the data layer (events in the data layer will be sent to Google as soon as the gtag script has been loaded)
                window.dataLayer = window.dataLayer || [];
                this.gtag = function () { window.dataLayer.push(arguments); }
                window.gtag = this.gtag;
                this.gtag('js', new Date());
                this.gtag('config', config.googleAnalyticsId!, { 'send_page_view': this.trackPageViewAtScriptLoad });

                // Add Google Ads tag
                if (config.googleAdsId != null)
                {
                    this.gtag('config', config.googleAdsId, { 'send_page_view': this.trackPageViewAtScriptLoad, 'allow_enhanced_conversions': true });
                }

                // Consent
                this.forceGoogleConsent();

                resolve();
                console.log('Tracker: Google Analytics loaded.');
            };
            document.body.appendChild(script);
        });
    }

    /**
     * Set all gtag.js consents to granted
     */
    private forceGoogleConsent()
    {
        if (!this.gtag) return;

        this.gtag('consent', 'update', {
            'ad_storage': 'granted',
            // @ts-ignore
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted',
            'functionality_storage': 'granted',
            'personalization_storage': 'granted'
        });
    }

    /**
    * Load the Facebook pixel code.
    * https://www.facebook.com/business/help/952192354843755?id=1205376682832142
    */
    private loadFacebookScript()
    {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;

        return new Promise<void>((resolve) =>
        {
            console.log('Tracker: loading Facebook pixel...');

            if (!this.trackWithFacebook)
            {
                console.log('Tracker: Facebook pixel disabled.');
                resolve();
                return;
            }

            // A heavily modified version of the Facebook pixel code
            const f = function (p_window?: any, p_document?: any, p_script?: any, p_src?: any, n?: any, script?: any, onLoaded?: any)
            {
                if (p_window.fbq) return;
                n = p_window.fbq = function ()
                {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!p_window._fbq) p_window._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];

                script = p_document.createElement(p_script);
                script.async = true;
                script.src = p_src;
                script.onload = () =>
                {
                    if (onLoaded) onLoaded();
                };
                document.body.appendChild(script);
            };

            f(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js', undefined, undefined, () =>
            {
                //fbq('set', 'autoConfig', 'false', config.facebookPixelID); 
                fbq('init', config.facebookPixelId!);
                if (this.trackPageViewAtScriptLoad) fbq('track', 'PageView');
                this.fbq = fbq;

                resolve();
                console.log('Tracker: Facebook pixel loaded.');
            });
        });
    }

    /**
     * Load the Twitter pixel code.
     * https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
     */
    private loadTwitterScript()
    {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;

        return new Promise<void>((resolve) =>
        {
            console.log('Tracker: loading Twitter pixel...');

            if (!this.trackWithTwitter)
            {
                console.log('Tracker: Twitter pixel disabled.');
                resolve();
                return;
            }

            const t = function (p_window?: any, p_document?: any, p_script?: any, p_src?: any, n?: any, script?: any, firstScript?: any)
            {
                if (p_window.twq) return;
                n = p_window.twq = function ()
                {
                    n.exe ? n.exe.apply(n, arguments) : n.queue.push(arguments);
                };
                n.version = '1.1';
                n.queue = [];

                script = p_document.createElement(p_script);
                script.async = true;
                script.src = p_src;
                firstScript = p_document.getElementsByTagName(p_script)[0];
                firstScript.parentNode.insertBefore(script, firstScript);
            };

            t(window, document, 'script', 'https://static.ads-twitter.com/uwt.js');
            this.twq = window.twq;

            this.twq('config', config.twitterPixelId!);

            resolve();
            console.log('Tracker: Twitter pixel loaded.');
        });
    }

    /**
     * Load the TikTok pixel code.
     * https://ads.tiktok.com/help/article/standard-events-parameters?lang=en
     */
    private loadTikTokScript()
    {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;

        return new Promise<void>((resolve) =>
        {
            console.log('Tracker: loading TikTok pixel...');

            if (!this.trackWithTikTok)
            {
                console.log('Tracker: TikTok pixel disabled.');
                resolve();
                return;
            }

            // A slightly modified version of the TikTok pixel code
            const t = function (w?: any, d?: any, t?: any)
            {
                w.TiktokAnalyticsObject = t;
                var ttq = w[t] = w[t] || [];
                ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"];
                ttq.setAndDefer = function (t: any, e: any) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } };
                for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
                ttq.instance = function (t: any)
                {
                    var e = ttq._i[t] || [];
                    for (var n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
                    return e;
                };
                ttq.load = function (e: any, n: any)
                {
                    var r = "https://analytics.tiktok.com/i18n/pixel/events.js", o = n && n.partner;
                    ttq._i = ttq._i || {};
                    ttq._i[e] = [];
                    ttq._i[e]._u = r;
                    ttq._t = ttq._t || {};
                    ttq._t[e] = +new Date();
                    ttq._o = ttq._o || {};
                    ttq._o[e] = n || {};
                    n = d.createElement("script");
                    n.type = "text/javascript";
                    n.async = true;
                    n.src = r + "?sdkid=" + e + "&lib=" + t;
                    e = d.getElementsByTagName("script")[0];
                    e.parentNode.insertBefore(n, e);
                };
            };

            t(window, document, 'ttq');
            this.ttq = window.ttq;
            this.ttq.load(config.tikTokPixelId!);
            this.ttq.page();

            resolve();
            console.log('Tracker: TikTok pixel loaded.');
        });
    }

    /**
     * Load the Reddit Pixel script.
     * https://business.reddithelp.com/s/article/manual-conversion-events-with-the-reddit-pixel
     *
     * This method adapts Reddit’s base code. It checks whether Reddit tracking is enabled,
     * creates the rdt object (if needed), loads the Reddit script asynchronously, and then
     * calls the initialization and default "PageVisit" event.
     */
    private loadRedditScript()
    {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;

        return new Promise<void>((resolve) =>
        {
            console.log('Tracker: loading Reddit pixel...');

            if (!this.trackWithReddit)
            {
                console.log('Tracker: Reddit pixel disabled.');
                resolve();
                return;
            }

            // Initialize the Reddit Pixel base code
            let p: any = window.rdt = function ()
            {
                p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
            };
            p.callQueue = [];
            let t = document.createElement('script');
            t.src = 'https://www.redditstatic.com/ads/pixel.js';
            t.async = true;
            t.onload = () =>
            {
                if (config.redditPixelId)
                {
                    window.rdt('init', config.redditPixelId);
                }
                if (this.trackPageViewAtScriptLoad)
                {
                    window.rdt('track', 'PageVisit');
                }
                this.rdt = window.rdt;
                console.log('Tracker: Reddit pixel loaded.');
                resolve();
            };
            let s: any = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(t, s);
        });
    }

    /**
    * Log a custom event.
    * Google Ads and Twitter events cannot be logged with this method because they require a conversion label (Google Ads) or an event ID (Twitter).
    * 
    * @param name 
    * @param parameters 
    * @param extraUserData
    */
    async logEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        // Google
        this.logGoogleEvent(name, parameters);

        // Facebook
        this.logFacebookEvent(name, parameters);

        // Facebook Conversions API
        await this.logFacebookConversionsApiEvent(name, window?.location?.href, parameters, extraUserData);

        // TikTok
        await this.logTikTokEvent(name, parameters, extraUserData);

        // Reddit & Reddit Conversions API
        await this.logRedditEvent(name, parameters, extraUserData);
    }

    /**
     * Prepare the parameters for a tracking event.
     * 
     * @param parameters The parameters to prepare. If not set a new object will be created.
     */
    prepareParameters(parameters: any = {})
    {
        if (parameters == null) parameters = {};
        parameters.version = config.version;
        return parameters;
    }

    /**
    * Log a page view (Google only).
    * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
    */
    logPageView(pageTitle: string = document.title, pageUrl: string = location.href, extraUserData?: TrackerExtraUserData)
    {
        // Google
        let parameters: any = {
            page_title: pageTitle,
            page_location: pageUrl,
        };
        this.logGoogleEvent('page_view', parameters);

        // Facebook
        parameters = {
            content_name: pageTitle,
        };
        this.logFacebookEvent('ViewContent', parameters);

        // Facebook Conversions API
        this.logFacebookConversionsApiEvent('ViewContent', pageUrl, parameters, extraUserData);
    }

    /**
     * Log an eCommerce related event.
     * 
     * @param data eCommerce data
     * @param extraUserData
     */
    logEcommerceEvent(data: TrackerEcommerceData, extraUserData?: TrackerExtraUserData)
    {
        // Google data (GA4)
        // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
        let googleData = {
            transaction_id: data.transactionId,
            value: data.value,
            currency: data.currency,
            tax: data.tax,
            shipping: data.shippingCost,
            coupon: data.couponCode,
            payment_type: data.paymentType,
            items: data.products.map(product => ({
                item_id: product.id,
                item_name: product.name,
                price: product.price,
                quantity: product.quantity,
                item_category: product.category,
                item_brand: product.brand,
                item_variant: product.variant,
                google_business_vertical: 'retail'
            }))
        };

        // Facebook data
        // https://developers.facebook.com/docs/meta-pixel/reference#standard-events
        let facebookData = {
            currency: data.currency,
            value: data.value,
            content_type: 'product',
            content_category: data.category,
            contents: data.products.map(product => ({
                id: product.id,
                title: product.name,
                item_price: product.price,
                quantity: product.quantity,
            }))
        };

        // https://support.google.com/google-ads/answer/7305793?hl=en
        let googleAdsData = {
            transaction_id: data.transactionId,
            value: data.value,
            currency: data.currency,
            items: data.products.map(product => ({
                id: product.id,
                google_business_vertical: 'retail'
            }))
        };

        // https://business-api.tiktok.com/portal/docs?id=1739585702922241
        let tikTokData: any = {
            order_id: data.transactionId,
            content_type: 'product',
            contents: data.products.map(product => ({
                content_id: product.id.toString(),
                content_name: product.name,
                quantity: product.quantity,
                price: product.price,
            })),
            content_category: data.category,
            value: data.value,
            currency: data.currency,
        };
        if (data.transactionId)
        {
            tikTokData.event_id = data.transactionId.toString();
        }

        // https://business.reddithelp.com/s/article/supported-conversion-events
        // https://business.reddithelp.com/s/article/about-event-metadata
        // For Reddit we use the standard event names and Custom + customEventName for custom events
        let totalItemCount = data.products.reduce((sum, product) => sum + (product.quantity ?? 1), 0);
        let redditParameters: any = {
            value: data.value,
            value_decimal: data.value, // for the server-side API
            currency: data.currency,
            itemCount: totalItemCount,
            products: data.products.map(product => ({
                id: product.id.toString(),
                name: product.name,
                category: product.category,
            }))
        };
        if (data.transactionId) 
        {
            redditParameters.conversion_id = data.transactionId.toString(); // for the server-side API
        }

        // Event name
        let googleEventName: string = '';
        let facebookEventName: string = '';
        let tikTokEventName: string = '';
        let redditEventName: string = '';

        switch (data.type)
        {
            case TrackerEcommerceEventType.ViewProducts:
                {
                    googleEventName = 'view_item';
                    facebookEventName = 'ViewContent';
                    tikTokEventName = 'ViewContent';
                    redditEventName = 'ViewContent';
                }
                break;
            case TrackerEcommerceEventType.AddToWishList:
                {
                    googleEventName = 'add_to_wishlist';
                    facebookEventName = 'AddToWishlist';
                    tikTokEventName = 'AddToWishlist';
                    redditEventName = 'AddToWishlist';
                }
                break;
            case TrackerEcommerceEventType.AddToCart:
                {
                    googleEventName = 'add_to_cart';
                    facebookEventName = 'AddToCart';
                    tikTokEventName = 'AddToCart';
                    redditEventName = 'AddToCart';
                }
                break;
            case TrackerEcommerceEventType.RemoveFromCart:
                {
                    googleEventName = 'remove_from_cart';
                    facebookEventName = 'RemoveFromCart';
                    tikTokEventName = 'RemoveFromCart';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'RemoveFromCart';
                }
                break;
            case TrackerEcommerceEventType.ViewCart:
                {
                    googleEventName = 'view_cart';
                    facebookEventName = 'ViewCart';
                    tikTokEventName = 'ViewCart';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'ViewCart';
                }
                break;
            case TrackerEcommerceEventType.InitiateCheckout:
                {
                    googleEventName = 'begin_checkout';
                    facebookEventName = 'InitiateCheckout';
                    tikTokEventName = 'InitiateCheckout';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'InitiateCheckout';
                }
                break;
            case TrackerEcommerceEventType.AddPaymentInfo:
                {
                    googleEventName = 'add_payment_info';
                    facebookEventName = 'AddPaymentInfo';
                    tikTokEventName = 'AddPaymentInfo';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'AddPaymentInfo';
                }
                break;
            case TrackerEcommerceEventType.Purchase:
                {
                    googleEventName = 'purchase';
                    facebookEventName = 'Purchase';
                    tikTokEventName = 'PlaceAnOrder';
                    redditEventName = 'Purchase';
                }
                break;
            case TrackerEcommerceEventType.PaymentFailed:
                {
                    googleEventName = 'payment_failed';
                    facebookEventName = 'PaymentFailed';
                    tikTokEventName = 'PaymentFailed';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'PaymentFailed';
                }
                break;
            case TrackerEcommerceEventType.ChangePaymentMethod:
                {
                    googleEventName = 'change_payment_method';
                    facebookEventName = 'ChangePaymentMethod';
                    tikTokEventName = 'ChangePaymentMethod';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'ChangePaymentMethod';
                }
                break;
            case TrackerEcommerceEventType.Refund:
                {
                    googleEventName = 'refund';
                    facebookEventName = 'Refund';
                    tikTokEventName = 'Refund';
                    redditEventName = 'Custom';
                    redditParameters.customEventName = 'Refund';
                }
                break;
        }

        // Log GA4 event
        this.logGoogleEvent(googleEventName, googleData, config.googleAnalyticsId);

        // Log Google Ads event
        if (config.googleAdsId != null)
        {
            this.logGoogleEvent(googleEventName, googleAdsData, config.googleAdsId);
        }

        // Log Facebook event
        this.logFacebookEvent(facebookEventName, facebookData);
        this.logFacebookConversionsApiEvent(facebookEventName, window?.location?.href, facebookData, extraUserData);

        // Log TikTok event
        this.logTikTokEvent(tikTokEventName, tikTokData, extraUserData);

        // Log Reddit event & Reddit Conversions API event
        this.logRedditEvent(redditEventName, redditParameters, extraUserData);
    }

    /**
    * Log a Google event with gtag.js.
    * https://developers.google.com/analytics/devguides/collection/gtagjs/events
    * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
    * 
    * @param name Event name. 
    * @param parameters Event parameters.
    * @param sendTo The Google Analytics ID to send the event to. If not set, the event will be sent to the default Google Analytics ID.
    */
    logGoogleEvent(name: string, parameters: any = {}, sendTo?: string)
    {
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;
        let sendToLogText = sendTo !== undefined ? ` (sendTo: '${sendTo}'). ` : '';
        // console.log(`Tracker: log Google event '${name}'. ${sendToLogText}Parameters:`);
        // console.log(parameters);

        parameters = this.prepareParameters(parameters);
        if (sendTo !== undefined) parameters.send_to = sendTo;

        this.gtag('event', name, parameters);
    }

    /**
     * Log a Google Ads conversion event.
     * https://support.google.com/google-ads/answer/7548399 (Event snippet)
     * https://support.google.com/google-ads/answer/12785474?visit_id=638155969923163305-1440919079&rd=1# (Enhanced conversions)
     * https://www.semetis.com/en/resources/articles/how-to-debug-google-enhanced-conversions-implementation
     * https://support.google.com/google-ads/answer/11956168#zippy=%2Csetup-has-incorrect-data-formatting
     * 
     * @param conversionLabel The conversion label
     * @param parameters The conversion parameters.
     */
    logGoogleAdsEvent(conversionLabel: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithGoogle || this.gtag === undefined) return;

        // console.log('Tracker: log Google Ads event ' + conversionLabel + '. Parameters:');
        // console.log(parameters);
        // console.log('Extra user data:');
        // console.log(extraUserData);

        parameters = this.prepareParameters(parameters);

        // Google Ads enhanced conversion user data
        // https://support.google.com/google-ads/answer/13258081?hl=en#zippy=%2Cidentify-and-define-your-enhanced-conversions-fields
        if (extraUserData !== undefined && this.gtag !== undefined)
        {
            let user_data = {
                email: extraUserData.emails !== undefined && extraUserData.emails.length > 0 ? extraUserData.emails[0] : undefined,
                phone_number: extraUserData.phoneNumbers !== undefined && extraUserData.phoneNumbers.length > 0 ? extraUserData.phoneNumbers[0] : undefined,
                address: {
                    first_name: extraUserData.firstName,
                    last_name: extraUserData.lastName,
                    postal_code: extraUserData.postalCode,
                    street: extraUserData.streetAddress,
                    country: extraUserData.countryCode
                }
            };

            // console.log('user_data:');
            // console.log(user_data);

            this.gtag('set', 'user_data', user_data);
        }

        // Log the event
        this.logGoogleEvent('conversion', {
            send_to: config.googleAdsId + '/' + conversionLabel,
            ...parameters
        });
    }

    /**
    * Log a Facebook pixel event via JS
    * https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking
    * 
    * @param name Event name. 
    * @param parameters Event parameters.
    */
    logFacebookEvent(name: string, parameters: any = {})
    {
        if (!this.enabled || !this.trackWithFacebook || this.fbq === undefined) return;

        // console.log('Tracker: log Facebook event ' + name + '');

        parameters = this.prepareParameters(parameters);
        this.fbq('trackCustom', name, parameters);
    }


    /**
     * Log a Twitter pixel event.
     * https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
     */
    logTwitterEvent(name: string, parameters: any = {})
    {
        if (!this.enabled || !this.trackWithTwitter || this.twq === undefined) return;

        // console.log('Tracker: log Twitter event ' + name + '');

        parameters = this.prepareParameters(parameters);
        this.twq('event', name, parameters);
    }

    /**
     * Log a TikTok pixel event.
     * https://ads.tiktok.com/help/article/custom-events?lang=en
     */
    async logTikTokEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithTikTok || this.ttq === undefined) return;

        // console.log('Tracker: log TikTok event ' + name + '');

        parameters = this.prepareParameters(parameters);
        let tikTokParameters = { ...parameters };
        tikTokParameters.event_id = parameters.event_id ?? uniqueId();
        this.ttq.track(name, tikTokParameters);

        // TikTok Events API (if enabled)
        await this.logTikTokEventApiEvent(name, window?.location?.href, tikTokParameters, extraUserData);
    }

    /** List of standard events that Reddit recognizes. */
    standardRedditEvents = [
        'PageVisit',
        'ViewContent',
        'Search',
        'AddToCart',
        'AddToWishlist',
        'Purchase',
        'Lead',
        'SignUp'
    ];

    /**
     * Log a Reddit pixel event.
     * Log the Reddit Conversion API event as well (if enabled)
     * https://business.reddithelp.com/s/article/manual-conversion-events-with-the-reddit-pixel
     *
     * @param name Event name.
     * @param parameters Event parameters.
     */
    async logRedditEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData) 
    {
        if (!this.enabled || !this.trackWithReddit || this.rdt === undefined) return;

        parameters = this.prepareParameters(parameters);

        // For standard events, use the event name directly, for custom events use 'Custom' as the name and supply a 'customEventName' property in the parameters
        if (!this.standardRedditEvents.includes(name))
        {
            parameters.customEventName = name; // set the custom event name from the 'name' parameter
            name = 'Custom'; // Rename the event to 'Custom' (Reddit requires custom events to be named 'Custom')
        }

        // If extra user data is provided and contains an email: add the email for advanced matching
        // https://business.reddithelp.com/s/article/about-advanced-matching
        if (extraUserData !== undefined && extraUserData.emails !== undefined && extraUserData.emails.length > 0)
        {
            parameters.email = extraUserData.emails[0];
        }

        // Add a conversion ID to the parameters (if not provided)
        let redditParameters = { ...parameters }; // create a copy of the parameters to not interfere with the other tracking methods
        redditParameters.conversion_id = parameters.conversion_id ?? uniqueId();

        this.rdt('track', name, redditParameters);

        // Log the event with the Reddit Conversion API (if enabled)
        await this.logRedditConversionsApiEvent(name, redditParameters, extraUserData);
    }

    /**
     * Log an event with the Google Analytics Measurement Protocol.
     * https://developers.google.com/analytics/devguides/collection/protocol/ga4
     * 
     * @param name Event name.
     * @param parameters Event parameters.
     * @param extraUserData Extra user data.
     */
    async logGoogleMeasurementProtocolEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithGoogleMeasurementProtocol) return;

        // console.log('Tracker: log Google Analytics Measurement Protocol event ' + name + '');

        // dynamic import to avoid loading the whole apollo client in the bundle
        const { client } = await import('@xFrame4/business/GraphQlClient');
        const { gql } = await import('@apollo/client');

        let mutation = `
            mutation TrackWithGoogleMeasurementProtocol($input: GoogleMeasurementProtocolInput!) {
                trackWithGoogleMeasurementProtocol(input: $input)
            }
        `;

        let input = {
            measurementId: config.googleAnalyticsId,
            clientId: extraUserData?.gaClientId,
            eventName: name,
            parameters: JSON.stringify(parameters),
        };

        let { data } = await client.mutate({
            mutation: gql(mutation),
            variables: {
                input: input
            }
        });

        return data.trackWithGoogleMeasurementProtocol as boolean;
    }

    /**
     * Log an event with the Facebook Conversion API.
     * 
     * @param name Event name.
     * @param sourceUrl The site URL where the event happened.
     * @param parameters Event parameters.
     */
    async logFacebookConversionsApiEvent(name: string, sourceUrl: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithFacebookConversionsApi) return;

        parameters = this.prepareParameters(parameters);    

        // dynamic import to avoid loading the whole apollo client in the bundle
        const { client } = await import('@xFrame4/business/GraphQlClient');
        const { gql } = await import('@apollo/client');

        let mutation = `
            mutation TrackWithFacebookConversionsApi($input: FacebookConversionApiInput!) {
                trackWithFacebookConversionsApi(input: $input)
            }
        `;

        // Add the test event code if it is set.
        let facebookCapiParameters = {...parameters, test_event_code: config.facebookTestEventCode};

        let input = {
            eventName: name,
            eventSourceUrl: sourceUrl,
            parameters: JSON.stringify(facebookCapiParameters),
            userParameters: JSON.stringify({
                emails: extraUserData?.emails ?? [],
                phones: extraUserData?.phoneNumbers ?? [],
                firstName: extraUserData?.firstName,
                lastName: extraUserData?.lastName,
                //_fbp: extraUserData?.fbp, // for a strange reason I've had to add the underscore otherwise Apollo gives 'Invalid parameter' error
                //_fbc: extraUserData?.fbc,
            })
        };

        let { data } = await client.mutate({
            mutation: gql(mutation),
            variables: {
                input: input
            }
        });

        return data.trackWithFacebookConversionsApi as boolean;
    }

    /**
     * Log an event with the TikTok Events API.
     * 
     * @param name Event name.
     * @param sourceUrl The site URL where the event happened.
     * @param parameters Event parameters.
     * @param extraUserData Extra user data.
     */
    async logTikTokEventApiEvent(name: string, sourceUrl: string, parameters: any = {}, extraUserData?: TrackerExtraUserData)
    {
        if (!this.enabled || !this.trackWithTikTokEventApi) return;

        parameters = this.prepareParameters(parameters);    

        // dynamic import to avoid loading the whole apollo client in the bundle
        const { client } = await import('@xFrame4/business/GraphQlClient');
        const { gql } = await import('@apollo/client');

        let mutation = `
            mutation TrackWithTikTokEventsApi($input: TikTokEventsApiInput!) {
                trackWithTikTokEventsApi(input: $input)
            }
        `;

        // Build the userParameters object.
        const userParameters = {
            emails: extraUserData?.emails ?? [],
            phones: extraUserData?.phoneNumbers ?? [],
            externalIds: extraUserData?.externalIds ?? [],
        };

        // Add the test event code if it is set.
        let tikTokParameters = {...parameters, test_event_code: config.tikTokTestEventCode};

        // Build the payload for the API.
        const payload = {
            eventName: name,
            eventSourceUrl: sourceUrl,
            parameters: tikTokParameters,
            userParameters: userParameters,
        };

        let { data } = await client.mutate({
            mutation: gql(mutation),
            variables: {
                input: payload
            }
        });

        return data.trackWithTikTokEventsApi as boolean;
    }

    /**
     * Log an event with the Reddit Conversion API.
     * 
     * This method sends the conversion event data to your GraphQL API,
     * which is then responsible for making the actual API call to Reddit.
     * 
     * @param name - The name of the event.
     * @param parameters - Extra event parameters (for example, click_id, user, event_metadata, etc.).
     * @param extraUserData - Additional user information for matching.
     * @param conversionID - The conversion ID to match the server-side event to a client side event. Can be set in the parameters as well. This is used if the value is not set in the parameters.
     */
    async logRedditConversionsApiEvent(name: string, parameters: any = {}, extraUserData?: TrackerExtraUserData, conversionID?: string)
    {
        if (!this.enabled || !this.trackWithRedditConversionsApi) return;

        // Dynamic import to avoid loading the whole Apollo client in the bundle.
        const { client } = await import('@xFrame4/business/GraphQlClient');
        const { gql } = await import('@apollo/client');

        // Define the GraphQL mutation.
        const mutation = gql`
            mutation TrackWithRedditConversionsApi($input: RedditConversionApiInput!) {
                trackWithRedditConversionsApi(input: $input)
            }
        `;

        // Build the event payload.
        // The Reddit API requires an ISO 8601 date for when the event occurred.
        // Standard events are sent with their event name directly;
        // otherwise, we use a custom event with a custom_event_name.
        let eventPayload: any = {
            event_at: new Date().toISOString(),
            event_type: {} as any
        };

        if (this.standardRedditEvents.includes(name))
        {
            eventPayload.event_type.tracking_type = name;
        }
        else
        {
            eventPayload.event_type.tracking_type = 'Custom';
            eventPayload.event_type.custom_event_name = name;
        }

        // Build the attribution matching signals.
        // These signals help Reddit match the conversion event to an ad engagement.
        const attributionMatchingSignals = {
            // Reddit Click ID: try the provided value first; if not, extract it from the URL query string (e.g. ?rdt_cid=12345)
            click_id: parameters.click_id ?? Tracker.getQueryParam('rdt_cid'),
            // Reddit UUID: if not provided, attempt to retrieve from the Reddit pixel object (if available)
            reddit_uuid: parameters.reddit_uuid ?? (typeof window !== 'undefined' && window.rdt && window.rdt.uuid ? window.rdt.uuid : undefined),
            // Device data: user agent, and screen dimensions.
            device: {
                user_agent: parameters.user_agent ?? (typeof navigator !== 'undefined' ? navigator.userAgent : undefined),
                screen_dimensions: parameters.screen_dimensions ?? (typeof window !== 'undefined' && window.screen ? {
                    width: window.screen.width,
                    height: window.screen.height
                } : undefined),
            },
            // Advanced matching signals: email (from extraUserData), mobile advertising ID (IDFA/AAID), and an external ID.
            advanced_matching: {
                email: extraUserData?.emails && extraUserData.emails.length > 0 ? extraUserData.emails[0] : undefined,
                external_id: parameters.external_id || undefined,
                idfa: parameters.idfa ?? undefined,
                aaid: parameters.aaid ?? undefined,
                uuid: parameters.uuid ?? undefined,
            }
        };

        // Add the attribution matching signals to the event payload.
        // https://ads.reddit.com/account/gg974fm2vblw/events-manager/manual-setup/pixel-and-capi?group=capi&step=addParameters
        // https://ads-api.reddit.com/docs/v2/#section/Prerequisites-and-considerations
        // The IP address must be added at server-side.
        eventPayload.click_id = attributionMatchingSignals.click_id;
        eventPayload.user = {
            user_agent: attributionMatchingSignals.device.user_agent,
            screen_dimensions: {
                width: attributionMatchingSignals.device.screen_dimensions?.width,
                height: attributionMatchingSignals.device.screen_dimensions?.height
            },
            email: attributionMatchingSignals.advanced_matching.email,
            external_id: attributionMatchingSignals.advanced_matching.external_id,
            idfa: attributionMatchingSignals.advanced_matching.idfa,
            aaid: attributionMatchingSignals.advanced_matching.aaid,
            uuid: attributionMatchingSignals.advanced_matching.uuid
        }

        // Add the event metadata to the event payload (the parameters need to be converted to snake_case at server-side - most probably GraphQL will do this automatically).
        // https://ads-api.reddit.com/docs/v2/#section/Verify-conversion-events
        eventPayload.event_metadata = { ...parameters }; // create a copy of the parameters to not interfere with the other tracking methods

        // Set the conversion ID, check if it is set in the parameters => if not, use the conversionID => if not, generate a new unique ID.
        eventPayload.event_metadata.conversion_id = parameters.conversion_id ?? conversionID ?? uniqueId();

        // Call the GraphQL API
        let input = {
            events: JSON.stringify([eventPayload]),
        };

        const { data } = await client.mutate({
            mutation,
            variables: {
                input: input
            }
        });

        return data.trackWithRedditConversionsApi as boolean;
    }

    /**
     * Helper method to get a query parameter from the URL.
     */
    static getQueryParam(param: string): string | undefined 
    {
        if (typeof window === 'undefined') return undefined;
        const searchParams = new URLSearchParams(window.location.search);

        return searchParams.get(param) || undefined;
    }
}

export default Tracker;