import Tracker from '@xFrame4/tracking/Tracker';

export enum AppTrackerEvent
{
    SharePixie = 'share_pixie',
}

export class AppTracker extends Tracker
{
    static createInstance(): AppTracker
    {
        return new AppTracker();
    }

    protected async init()
    {
        this.trackWithGoogle = true;
        this.trackPageViewAtScriptLoad = false;

        super.init();
    }
}