import { FunctionComponent, useEffect } from 'react';
import Head from 'next/head'
import config, { AppLanguage } from '@config';
import { useAppStateSelector } from '@redux/hooks';

interface CustomHeadProps
{
    metaData?: HeadMetaData;
}

/**
 * For older Next.js versions (pages router)
 */
const CustomHead: FunctionComponent<CustomHeadProps> = (props) =>
{
    const appLanguage: AppLanguage = useAppStateSelector(state => state.layout.language);
    
    useEffect(() => {
        document.documentElement.setAttribute('lang', appLanguage.code);
    }, [appLanguage]);

    // Open Graph Meta (get default values from standard meta)
    let ogTitle = props.metaData?.ogMeta?.title ?? props.metaData?.title;
    let ogDescription = props.metaData?.ogMeta?.description ?? props.metaData?.metaDescription;
    let ogType = props.metaData?.ogMeta?.type ?? 'website';
    
    /** Render */
    return (
        <>
            <Head>
                {/* Title */}
                {props.metaData?.title && <title>{props.metaData.title}</title>}
                
                {/* Standard meta Tags */}
                {props.metaData?.metaDescription && <meta name="description" content={props.metaData.metaDescription} />}
                {props.metaData?.metaKeywords && <meta name="keywords" content={props.metaData.metaKeywords} />}

                {/* Conditionally render noindex tag */}
                {(props.metaData?.noIndex || config.preventSearchCrawler) && <meta name="robots" content="noindex" />}

                {/* Open Graph Meta */}
                {props.metaData?.ogMeta &&
                    <>
                    {ogTitle && <meta property="og:title" content={ogTitle} />}
                    {ogDescription && <meta property="og:description" content={ogDescription} />}
                    {props.metaData.ogMeta.url && <meta property="og:url" content={props.metaData.ogMeta.url} />}
                    {ogType && <meta property="og:type" content={ogType} />}
                    
                    {props.metaData.ogMeta.image &&
                        <>
                            {props.metaData.ogMeta.image.url && <meta property="og:image" content={props.metaData.ogMeta.image.url} />}
                            {props.metaData.ogMeta.image.type && <meta property="og:image:type" content={props.metaData.ogMeta.image.type} />}
                            {props.metaData.ogMeta.image.width && <meta property="og:image:width" content={props.metaData.ogMeta.image.width.toString()} />}
                            {props.metaData.ogMeta.image.height && <meta property="og:image:height" content={props.metaData.ogMeta.image.height.toString()} />}
                        </>
                    }
                    </>
                }
            </Head>
        </>
    );
}

export interface HeadMetaData
{
    title?: string;
    metaDescription?: string;
    metaKeywords?: string;
    noIndex?: boolean;
    ogMeta?: OpenGraphMeta;
}

export interface OpenGraphMeta
{
    title?: string;
    description?: string;
    url?: string;
    type?: string
    image?: OpenGraghImage;
}

export interface OpenGraghImage
{
    url: string;
    type?: 'image/jpeg' | 'image/png' | 'image/webp';
    width?: number;
    height?: number; 
}

export default CustomHead;